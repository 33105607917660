<template>
	<div  :style="'background: '+webset['homebakcolor']" class="home-wrapper">
		<search :logo="webset.logo" :webset="webset"  leimulist="topmenu" />
		<!-- banner -->
		 <div>
		<div class="banner w1200">
			<el-carousel trigger="click" height="400px">
				<el-carousel-item v-for="(item,index) in banner" :key="index">
					<img :src="item.image" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
<div class="terms">
<p>Unique Fencing 條款及細則</p><br>
<p>條款及細則：<br>如要在本網店購物，您必須年滿18歲或以上。如果未滿18歲，則需在父母或監護人的陪同下在 本網店購物。</p>
<p><br></p>
<p>送貨政策：<br>我們的目標是按照您在訂單中要求的收貨地點以及在您的訂單結帳時我們指定的運送日期（在訂單確認中更新）將產品運送給您；<br>如果我們預計無法在預計的運送日期送貨，我們將嘗試通知您。<br>但是，在法律允許的範圍內，任何由於延遲運送而對您造成的任何損失、責任、成本、損害、收費或費用，我們不承擔任何責任。</p>
<p><br></p>
<p>退款/退貨/訂單取消政策：<br>所有貨品不設退錢及退貨。如果因為任何原因未對產品百分百滿意，可以在訂購後30天內退還並且必須符合我們的退貨政策。<br>退還的產品必須未經使用、產品標籤不得被篡改或拆除，並且產品應放於原裝包裝盒或包裝袋內（如適用）。<br>本政策僅適用於本網店上那些不在不設退換品牌/產品列表内的項目。</p>
<p><br></p>
課程退款政策：
<br>
購買課程後 14 天內，扣除已上課堂後，餘額可以全數退款。<br>
14 天后，將不予退款。<br>
退款將在請求後 5-7 個工作天內處理。<br>
所有退款請求必須以書面形式提交至：uniquefencing@gmail.com。
<p><br></p>
<p>結帳及付款流程：<br>您可以使用平台上列出所提供的付款方式或使用貨到付款方式。</p>
</div>	
	</div>
</div>
</template>

<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search'
import Product from '@/components/Product'

export default {
    name: 'Home',
    components: {Search, Product},
	data() {
		return {
			html:'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
			topmenu:[],
			//uniacid:0,
			uniacid:20,
			moduleList: [],
			leimugoodslist: [],
			banner: [],
			webset:[],
			menu: {
				active: '',
				list: []
			},
			leimulist:[],
			size: 10,
			page: 1,
			pageCount: 0,
			goodsList: [],
			leimu:[],
			nowleimu:'',
		}
	},
	created() {	
			console.log('home...');
		
		 this.getuniacid()
		 	if(this.$route.query.code){
				console.log('leimu:',this.$route.query.code);
				   this.nowleimu= this.$route.query.code
				}
		
	        	 
	
		//this.getGoodsList()
	
	},
	methods: {
		// 获取首頁模块
		async getuniacid(){
			// this.uniacid= 4 ;
			 var ss = window.localStorage.getItem('webinfo');
			 if(!ss){
				 var url = window.location.href;
				 var dz_url = url.split("#")[0];
				 var www_dz_url = dz_url.split("/")[2];
				 			
				
				var centerdomain= www_dz_url.split(".");
				console.log('centerdomain:',centerdomain);
				let res
				if(centerdomain[1]=='nowaittech'){
					res = await this.$http.get('app.layout/weblist',{centerdomain:centerdomain[0]})
					console.log('websiteinfo:',res);
				}else{
					 res = await this.$http.get('app.layout/weblist',{siteroot:www_dz_url})
					console.log('websiteinfo:',res);
				}
				
				 
				 console.log('websiteinfo:',res);
				 ss = JSON.stringify(res[0])		
		
			 }
			 var ssarray = JSON.parse(ss)
			 console.log('ss',ss);
			 console.log('ssarray.ucid',ssarray['ucid']);
			 this.webset =  ssarray 
			 //this.uniacid = ssarray['ucid'] 
			 this.uniacid=20;
			 window.localStorage.setItem('uniacid',this.uniacid)
			 //window.localStorage.setItem('uniacid',24)
			 document.title = ssarray.webtitle 
			// this.getwebset()
			 this.getleimugoods()
		},
		
		async getHomeLayout() {
			let res = await this.$http.get('/app.layout', {
				url: '/pages/tabbar/index/index',uniacid:this.uniacid
			})
			if (res.code !== 0) return
			this.moduleList = res.data.list
			this.banner = res.data.list.reduce((arr,item)=>{
				item.type == 'carousel' && (arr = item.data)
				return arr
			},[])
			console.log('banner',this.banner);
		},



		//获取分类
		async getleimu(){
			let res = await this.$http.get('/app.layout/mulu',{uniacid:this.uniacid})
			if(res){
				this.menu.list = res.itemtitle.map((item,index)=>{
					let obj = {label: item, id: res.itemcode[index]}
					return  obj
				})
			}
		},
		async gettopmenu(){
			let res = await this.$http.get('/user/topmenu',{uniacid:this.uniacid})
			if(res){
				 this.topmenu= res.data 
			 
			}
		},
		async getleimugoods(){
			let res = await this.$http.get('/app.layout/getmulugoods',{
				uniacid:this.uniacid
			})
			if(res){
				console.log('gsl',res);
				   this.leimugoodslist = res 
				   this.getHomeLayout()
				    
				    this.getleimu1()
			}
		},
		
		async getwebset(){
			let res = await this.$http.get('/app.layout/getwebset?id=4',{})
			if(res){
				console.log('uniacid',this.uniacid);
				console.log('webset',res);
				   this.webset = res 
				  
			}
		},
		
		async getleimu1(){
				console.log('mulu1');
				let res = await this.$http.get('/app.layout/mulu1', {
					uniacid:this.uniacid 	 
				})
				if(res){
					console.log(res);
					this.leimulist = res 
						 
					console.log('ok');
					console.log(this.leimulist);
					let locale = window.localStorage.getItem('locale') || this.$i18n.locale
					if(locale === 'zh-CN') {
						for (let i = 0; i < res.length; i++) {
							const str = chineseLanguageLoader(res[i].title, { language: 'zh-CN' });
							this.leimulist[i].title = str;
						}
					}





				}
				
		},
		async getleimu2(){
				if(uni.getStorageSync('category_one1')){
					this.category_one=uni.getStorageSync('category_one1')
					uni.setStorageSync("category_one1", '')
					console.log('one',this.category_one);
				}
				let res = await uni.$http.get('/app.layout/mulu2', {
					 father_code:this.category_one	 
				})
					  if(res.data){
						  this.leimutitle = res.data
					      console.log(this.leimutitle);
						  console.log('ok:leimu2');
					  }
				
		},

		//点击分类
		async onClickItem(e,f) {
			this.menu.active = e
			this.goodsList=[]
			this.page=0
			//this.getGoodsList()
		},

		//获取产品列表
		async getGoodsListT(one,two) {
			this.menu.active = two
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: 0,
				size: this.size,
				category_one: one,
				category_two: two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
		    this.leimugoodslist[one]['goods']=res.data.list
		 
		},
		//获取产品列表
		async getGoodsList(category_two) {
			if (this.page > 1 && this.page - 1 >= this.pageCount) return
			this.isLoading = true
			const res = await this.$http.get('/goods', {
				page: this.page++,
				size: this.size,
				category_one: this.menu.active,
				category_two: category_two,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			let data = res.data
			this.pageCount = Math.ceil(data.total / this.size)
			if (this.page <= 2) this.goodsList = []
			this.goodsList = this.goodsList.concat(data.list)
		},
	}
}
</script>
<style lang="scss" scoped>
    .home-wrapper {
        min-height: 75vh;

        .banner {
            height: 400px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .terms {
            font-size: 20px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4%;
            margin-bottom: 4%;
            max-width: 1214px; // Same as .w1200 max-width
        }
    }

    .w1200 {
        max-width: 1214px;
        margin: 0 auto;
    }
</style>
